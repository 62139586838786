define("ember-svg-jar/inlined/tidal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M84 83.5L.5 167l83 83c45.6 45.6 83.4 83 84 83 .5 0 38.3-37.4 84-83l83-83 83 83 83 83-83.5 83.5-83.5 83.5 83.5 83.5 83.5 83.5 83.5-83.5 83.5-83.5-83.3-83.3-83.2-83.2 83.3-83.3 83.2-83.2 83.3 83.2 83.2 83.3 83.5-83.5 83.5-83.5L917.5 83 834-.5 750.5 83 667 166.5l-83.3-83.3L500.5 0l-83.3 83.2-83.2 83.3-83.3-83.3L167.5 0 84 83.5z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "1334.667",
      "height": "889.333",
      "viewBox": "0 0 1001 667"
    }
  };
});