define("ember-svg-jar/inlined/deezer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M41.096 7.323c.444-2.574 1.095-4.192 1.817-4.196h.002c1.346.005 2.437 5.618 2.437 12.549 0 6.93-1.093 12.548-2.44 12.548-.553 0-1.063-.954-1.473-2.552-.649 5.851-1.995 9.874-3.553 9.874-1.205 0-2.287-2.416-3.014-6.227-.496 7.247-1.744 12.389-3.203 12.389-.916 0-1.75-2.038-2.369-5.355C28.558 43.2 26.84 48 24.838 48s-3.722-4.797-4.462-11.647c-.614 3.317-1.448 5.355-2.369 5.355-1.458 0-2.704-5.142-3.203-12.39-.726 3.812-1.805 6.228-3.014 6.228-1.556 0-2.904-4.021-3.552-9.874-.408 1.603-.92 2.552-1.473 2.552-1.348 0-2.44-5.618-2.44-12.548S5.417 3.127 6.765 3.127c.723 0 1.37 1.624 1.819 4.196C9.303 2.885 10.47 0 11.79 0c1.568 0 2.926 4.08 3.568 10.004.629-4.312 1.583-7.06 2.651-7.06 1.497 0 2.77 5.406 3.241 12.947.887-3.867 2.17-6.292 3.591-6.292 1.421 0 2.704 2.427 3.59 6.292.473-7.541 1.744-12.948 3.241-12.948 1.067 0 2.019 2.75 2.65 7.06C34.965 4.08 36.323 0 37.89 0c1.316 0 2.488 2.886 3.206 7.323zM.837 14.442c0-3.098.62-5.61 1.384-5.61.765 0 1.384 2.512 1.384 5.61 0 3.098-.62 5.61-1.384 5.61-.765 0-1.384-2.512-1.384-5.61zm45.231 0c0-3.098.62-5.61 1.384-5.61.765 0 1.385 2.512 1.385 5.61 0 3.098-.62 5.61-1.385 5.61-.764 0-1.384-2.512-1.384-5.61z\" fill=\"#A238FF\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
});